@font-face {
  font-family: "Maison";
  src: url("../../assets/brf/fonts/Maison-Regular.otf");
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "Maison-Light";
  src: url("../../assets/brf/fonts/MaisonNeue-Light.otf");
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "Maison-Bold";
  src: url("../../assets/brf/fonts/Maison-Bold.otf");
  font-style: normal;
  font-weight: bold;
}

