// Fonts
$font-family: 'Maison';

// Colors
$primary: #525D91;
$danger: #FF3C00;
$secondary: #707070; 
$theme-color-primary: #4A44B3;
$theme-color-secondary: #E06666;
$theme-hover: #CEBBD1;
$turn-off-the-light: rgba(0,0,0,.5);
$turn-off-the-light-2: rgba(0,0,0,.2);

$white: #fff;
$black: #000;
$white-half-opacity: rgba(255,255,255,.8);
$green: #00AA25;
$gray: #383838;
$gray-mid: #aaa9a9;
$gray-light: #E6E6E6;
$gray-lighter: #f5f5f5;

$amountOfLight: 10%;
$primary-dark: darken($primary, $amountOfLight);

