%flex-content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

%full-screen {
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

%retratil-element {
  position: absolute;
  height: 100vh;
  top: 0;
}