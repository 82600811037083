@import '../../../themes/brf/variables.scss';
.loader-gif{
    position: fixed;
    top: 0;
    left: 0;
    background-color: $gray-light;
    opacity: 0.6;
    width: 100%;
    height: 100%;
    z-index: 100000;

    div {
        position: fixed;
        top: 45%;
        left: 50%;
    }
}